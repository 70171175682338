import React, { useEffect, useState, useContext }  from 'react';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { MeuContexto } from "../App"

import { data_user } from '../DataAPI/datauser'
import { validarME } from '../DataAPI/apiMe'


import { global } from '../DataAPI/global'
import calcMD5 from '../Componentes/md5'

import personagem from '../img/person.png'

const PgUsuario = () => {



  let history = useHistory();



  const { logado, setlogado,setuser } = useContext(MeuContexto)

  const [isloading, setisloading] = useState(true);

  const [userid, setuserid] = useState(0)

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');
  const [isArtist, setIsArtist] = useState(false);
  const [style, setStyle] = useState('');
  const [cpf, setCpf] = useState('');
  const [title, setTitle] = useState('');
  const [isLivePaid, setIsLivePaid] = useState(false);
  const [price, setPrice] = useState('');
  const [isWifi, setIsWifi] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [errors, setErrors] = useState({});



  useEffect(
    () => {
      _carregatela()
    }, [])

  useEffect(
    () => {

      if (!logado) { history.push("/") }
    }, [logado])


  async function _carregatela() {
    const me = await validarME.me()
    if (me) {
      setuser(me)
      setuserid(me._id)
      setUsername(me.username)
      setEmail(me.email)
      setCpf(me.cpf)
      setComment(me.comment)
      setIsArtist(me.artist)


      setIsLivePaid(me.paga)
      setPrice(me.preco)
      setStyle(me.estilo)
      setTitle(me.title)
      setIsWifi(me.wifi)



      setisloading(false)

      if (me.fotoid !== '') {
        setProfilePhoto(`${global.brl.REACT_APP_FOTO}${me.fotoid}.jpg`)
      }

    } else {
      setuser([])
      setisloading(false)
      setlogado(false)
    }
  }



  async function _salvar() {


    const data = {

      id: userid,
      username: username,
      email: email,
      cpf: cpf,
      comment: comment,
      artist: isArtist,
      paga: isLivePaid,
      preco: price,
      estilo: style, title: title, wifi: isWifi,
    }

    const resposta = await data_user.atualizar(data);
    if (resposta) {
      setisloading(false)
      toast.success(

        'Sua conta foi atualizada!'
      );

    } else {

      setisloading(false)
      toast.error(

        'Não conseguimos atualizar a sua conta!'
      );

    }


  }


  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formErrors = {};

    if (!username) formErrors.username = 'Nome de usuário é obrigatório.';
    if (!email) {
      formErrors.email = 'E-mail é obrigatório.';
    } else if (!validateEmail(email)) {
      formErrors.email = 'Insira um e-mail válido.';
    }
    if (isArtist) {
      if (!style) formErrors.style = 'Estilo é obrigatório para pastores.';
      if (!cpf) formErrors.cpf = 'CPF é obrigatório para pastores.';
    }

    if (Object.keys(formErrors).length === 0) {
      console.log('Perfil atualizado:', {
        username,
        email,
        comment,
        isArtist,
        style,
        cpf,
        title,
        isLivePaid,
        price,
        isWifi,
        profilePhoto,
      });

      _salvar()

      toast.success('Perfil salvo com sucesso!');
    } else {
      setErrors(formErrors);
    }
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePhoto(URL.createObjectURL(file));
    }
  };

  return (
    <div className="flex justify-center mt-2 ">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold text-center mb-6">Perfil do Usuário</h2>

        <form onSubmit={handleSubmit}>
          {/* Foto de Perfil */}
          <div className="mb-6 text-center relative">
            <label htmlFor="profilePhoto" className="cursor-pointer">
              {profilePhoto ? (
                <img
                  src={profilePhoto}
                  alt="Foto de Perfil"
                  className="w-32 h-32 rounded-full mx-auto mb-4 object-cover border-2 border-gray-300"
                />
              ) : (
                <div className="w-32 h-32 rounded-full bg-gray-300 mx-auto mb-4 flex items-center justify-center border-2 border-gray-300">
                  <span className="text-gray-700">Clique para adicionar foto</span>
                </div>
              )}
            </label>
            <input
              type="file"
              id="profilePhoto"
              accept="image/*"
              onChange={handlePhotoChange}
              className="hidden"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Nome de Usuário
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 ${errors.username ? 'border-red-500 focus:ring-red-500' : 'focus:ring-indigo-950'
                }`}
              placeholder="Digite seu nome de usuário"
            />
            {errors.username && <p className="text-red-500 text-sm mt-1">{errors.username}</p>}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              E-mail
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 ${errors.email ? 'border-red-500 focus:ring-red-500' : 'focus:ring-indigo-950'
                }`}
              placeholder="Digite seu e-mail"
            />
            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="comment">
              Comentário
            </label>
            <textarea
              id="comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-950"
              placeholder="Escreva um comentário"
            />
          </div>

          <div className="mb-4">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={isArtist}
                onChange={(e) => setIsArtist(e.target.checked)}
                className="form-checkbox h-5 w-5 text-indigo-600"
              />
              <span className="ml-2 text-gray-700">Sou Pastor</span>
            </label>
          </div>

          {isArtist && (
            <>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="style">
                  Estilo
                </label>
                <select
                  id="style"
                  value={style}
                  onChange={(e) => setStyle(e.target.value)}
                  className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 ${errors.style ? 'border-red-500 focus:ring-red-500' : 'focus:ring-indigo-950'
                    }`}
                >
                  <option value="">Selecione um estilo</option>
                  <option value="pastor">Pastor</option>
                  <option value="igreja">Igreja</option>
                  <option value="musico">Músico</option>
                  <option value="fiel">Fiel</option>
                </select>
                {errors.style && <p className="text-red-500 text-sm mt-1">{errors.style}</p>}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cpf">
                  CPF
                </label>
                <input
                  type="text"
                  id="cpf"
                  value={cpf}
                  onChange={(e) => setCpf(e.target.value)}
                  className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 ${errors.cpf ? 'border-red-500 focus:ring-red-500' : 'focus:ring-indigo-950'
                    }`}
                  placeholder="Digite seu CPF"
                />
                {errors.cpf && <p className="text-red-500 text-sm mt-1">{errors.cpf}</p>}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
                  Título
                </label>
                <input
                  type="text"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-950"
                  placeholder="Digite o título"
                />
              </div>

              <div className="mb-4">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={isLivePaid}
                    onChange={(e) => setIsLivePaid(e.target.checked)}
                    className="form-checkbox h-5 w-5 text-indigo-600"
                  />
                  <span className="ml-2 text-gray-700">Live Paga</span>
                </label>
              </div>

              {isLivePaid && (
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="price">
                    Preço
                  </label>
                  <input
                    type="number"
                    id="price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-950"
                    placeholder="Digite o preço"
                    min="0"
                    step="10"
                  />
                </div>
              )}

              <div className="mb-4">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={isWifi}
                    onChange={(e) => setIsWifi(e.target.checked)}
                    className="form-checkbox h-5 w-5 text-indigo-600"
                  />
                  <span className="ml-2 text-gray-700">Transmissão via Wi-Fi</span>
                </label>
              </div>
            </>
          )}

          <button
            type="submit"
            className="w-full bg-indigo-950 text-white font-bold py-2 px-4 rounded-lg hover:bg-indigo-600 transition duration-300"
          >
            Salvar Perfil
          </button>
        </form>



        {/* Container para o toast */}
        <ToastContainer />

      </div>
    </div>
  );
};

export default PgUsuario;
