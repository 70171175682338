import React, { useEffect, useRef, useState } from 'react'
import { Link } from "react-router-dom";


import { MdChevronLeft, MdChevronRight } from "react-icons/md";

import Canal from './Canal'


function CanaisLista({ canaislist }) {

    const barref = useRef();

   
 

    const btesquerda = (barra) => {

        barra.scrollLeft -= 400
    }
    const btdireita = (barra) => {

        barra.scrollLeft += 400
    }


    return (
        <div>
            <MdChevronLeft className='hidden md:block  text-black text-[32px] absolute mx-1 mt-[100px] cursor-pointer z-40' onClick={() => { btesquerda(barref.current) }} />
            <MdChevronRight className='hidden md:block text-black text-[32px] absolute mx-4 mt-[100px] cursor-pointer right-0 z-40' onClick={() => { btdireita(barref.current) }} />

            <div className='flex gap-8 overflow-x-auto scrollbar-hide scroll-smooth p-4 pl-12' ref={barref}>


                {canaislist.map((item, index) => (
                    <div key={index}>
                        <Link to={`/Canal/${item.linktoken}`} >
                            <Canal item={item} />
                        </Link>
                    </div>



                ))}
            </div>

        </div>

    )
}

export default CanaisLista