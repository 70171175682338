import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link, useParams } from "react-router-dom";

import PgLivesItem from './PgLivesItem'
import CompActive from '../Componentes/CompActive'
import { MdSearch, MdAdd } from "react-icons/md";
import { data_live } from '../DataAPI/datalive'
import { validarME } from '../DataAPI/apiMe'
 

import { MeuContexto } from "../App"

import { global } from '../DataAPI/global'

import './PgLives.css';


import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";


import VideoPlayer from '../Componentes/CompVideoTrailer';

let listacompleta = []
let pagenumber = 1
let vrfilter = ''
let vrmeusartistas = false
let novofilter = ''



function PgLives() {


    let { Procura } = useParams();
    vrfilter = Procura

    if (vrfilter == 'Procurar') vrfilter = ''
    const { setlogado, setusername ,setuser} = useContext(MeuContexto)

    const [isloading, setisloading] = useState(true);

    const [liveson, setliveson] = useState([]) 




    const [livestrailer, setlivestrailer] = useState([])


    const [filter, setFilter] = useState(vrfilter);


    const [slide, setSlide] = useState(0);

    const nextSlide = () => {
        setSlide(slide === livestrailer.length - 1 ? 0 : slide + 1);
    };

    const prevSlide = () => {
        setSlide(slide === 0 ? livestrailer.length - 1 : slide - 1);
    };

    useEffect(
        () => {
            _carregatela()
        }, [])




    async function _carregatela() {


        const me = await validarME.me()
        if (me) {

            setuser(me)
            setusername(me.username)

            setisloading(false)

            setlogado(true)


        } else {
            setuser([])
            setisloading(false)
            setlogado(false)

        }

        Atualizar(1)

    }
    function _filtrar(e) {
        e.preventDefault()
        Atualizar(1)
    }
    async function Atualizar(page) {

        setisloading(true)

        AtualizarTrailer()

        pagenumber = page

        if (page === 1) {

            listacompleta = []

        }

        novofilter = ''


        if (vrmeusartistas) { novofilter = 'meusartistas' } else { novofilter = filter }

        const rlista1 = await data_live.Listar(global.brl.REACT_APP_LISTAID, page, novofilter);
        //    console.log(rlista1, 'rlista1')
        if (rlista1) {

            listacompleta = [...listacompleta, ...rlista1]

            if (rlista1.length < 10) {
                await Atualizar1(page)
            }
            else {
                Atualizar1(1)

            }

        }
        setliveson(listacompleta)



        setisloading(false)

        ///  if (global.linkurl != '') {

        // var perfillink = global.linkurl.replace('live/', '')
        // global.linkurl = ''
        /// navigation.navigate('scPerfil', { perfillink: perfillink })
        ///  }



    }

    async function Atualizar1(page) {

        const rlista2 = await data_live.Listar1(global.userid, page, novofilter);

        if (rlista2) {
            listacompleta = [...listacompleta, ...rlista2]


            if (rlista2.length < 10) {
                await Atualizar2(page)
            }
            else {
                Atualizar2(1)

            }

        }


    }
    async function Atualizar2(page) {

        const rlista3 = await data_live.Listar2(global.userid, page, novofilter);

        if (rlista3) {
            listacompleta = [...listacompleta, ...rlista3]

        }


    }

    async function AtualizarTrailer(page) {

        const rlistatrailer = await data_live.Trailer();

        if (rlistatrailer) {
            setlivestrailer(rlistatrailer)
        }


    }


    function _proximapg(e) {
        e.preventDefault()
        pagenumber++
        if (!isloading) { Atualizar(pagenumber) }

    }







    return (
        <div>

            <div className="liveConteudo">

                <form className="liveLocalizar" onSubmit={(e) => { _filtrar(e) }} autoComplete="off">

                    <input
                        className="liveinput"
                        autoComplete="off"
                        placeholder="Localizar"
                        name="localizar"
                        type="text"
                        value={filter}
                        onChange={e => setFilter(e.target.value)} />



                    <MdSearch className="lbt" onClick={(e) => { _filtrar(e) }} color={"gray"} size={'2rem'} />





                </form>

                {/*
                <div className="kr_conteiner">
                    <div className="kr_carousel">
                        <BsArrowLeftCircleFill onClick={prevSlide} className="kr_arrow kr_arrow-left" />
                        {livestrailer.map((item, idx) => {
                            return (
                                <Link to={`/Canal/${item.linktoken}`} className={slide === idx ? "kr_slide" : "kr_slide kr_slide-hidden"} >
                                    { /* ///  <img
                            ///        src={` ${+ (item.fototv === '') ? `${global.brl.REACT_APP_FOTO}${item.fotoid}.jpg` : `${global.brl.REACT_APP_FOTO}${item.fototv}.jpg`} `}
                              ///      alt={item._id}
                             //      key={idx}
                              //      className={slide === idx ? "kr_slide" : "kr_slide kr_slide-hidden"}
                             ///   />

                <div className={slide === idx ? "kr_slide" : "kr_slide kr_slide-hidden"}>

                                        {(slide === idx) ? <VideoPlayer videoSrc={`${global.brl.REACT_APP_EVIPPLAY1ini}trailer/${item._id}/index.m3u8`} /> : <></>}


                                    </div>
                                </Link>
                            );
                        })}
                        <BsArrowRightCircleFill
                            onClick={nextSlide}
                            className="kr_arrow kr_arrow-right"
                        />
                        <span className="kr_indicators">
                            {livestrailer.map((_, idx) => {
                                return (
                                    <button
                                        key={idx}
                                        className={
                                            slide === idx ? "kr_indicator" : "kr_indicator kr_indicator-inactive"
                                        }
                                        onClick={() => setSlide(idx)}
                                    ></button>
                                );
                            })}
                        </span>
                    </div>

                </div >
                */
                }


                <ul className="liveul">

                    {liveson.map((item) =>
                        <Link to={`/Canal/${item.linktoken}`} >
                            <li key={item._id} >

                                <PgLivesItem item={item} />

                            </li>
                        </Link>
                    )}

                </ul>

                <br></br>
                <br></br>
                <br></br>
                <div className="lbt2">
                    <MdAdd onClick={(e) => { _proximapg(e) }} color={"#0D0D0D"} size={'2rem'} />

                </div>


            </div>
            {(isloading) ? (<>
                <div className="lbt4">
                    <CompActive />
                </div>
            </>) : (<>
            </>)}
        </div>

    );
}

export default PgLives;