import React, { createContext, useState } from 'react';
import './App.css';
import Rotas from './Rotas'



export const MeuContexto = createContext();

function App() {



  const [logado, setlogado] = useState(false)
  const [username, setusername] = useState('')
  const [gett, setgett] = useState(false)

  const [perfil, setperfil] = useState([])


  const [user, setuser] = useState([])

  const [novousuario, setnovousuario] = useState(false)
 
  return (
    <MeuContexto.Provider value={{ logado, setlogado, username, setusername, gett, setgett, perfil, setperfil,novousuario,setnovousuario,user, setuser }} >
      <div className="xbackground">


        <Rotas />
        
      </div>
    </MeuContexto.Provider>

  );
}

export default App;
